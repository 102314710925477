import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import classnames from "classnames";
import { Drawer } from "./Drawer";
import * as css from "./Header.module.scss";

const Header = () => {
  const location = useLocation();

  return (
    <header className={classnames("_header", { [css.transparent]: location.pathname === "/" })}>
      <Link to="/">
        <img src="/images/logo.svg" alt="logo" />
      </Link>
      <Drawer />
    </header>
  );
};

export default Header;
