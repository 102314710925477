import { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import classnames from "classnames";
import LoadingButton from "@mui/lab/LoadingButton";
import { createCheckoutSession } from "@membership-edo/stripe";
import { useAuth } from "../hooks/auth";
import { usePlan } from "../hooks/plan";
import * as css from "./plan.module.scss";

const BasicPlan = ({
  priceId,
  selectedPriceId,
  amount,
  onClick,
}: {
  priceId: string;
  selectedPriceId: string;
  amount: number;
  onClick: (priceId: string) => void;
}) => {
  return (
    <div
      className={classnames(css.list, css.type, { [css.selected]: priceId === selectedPriceId })}
      onClick={() => onClick(priceId)}
    >
      <h2 className={css.title}>
        ベーシック
        <span>
          {new Intl.NumberFormat("ja-JP", { style: "currency", currency: "JPY" }).format(amount)}
          /月(税別)
        </span>
      </h2>
      <ul className={css.items}>
        <li>Facebookサロンの参加</li>
        <li>選挙への参加</li>
        <li>運営スタッフとして立候補</li>
        <li>年次総会への応募権</li>
        <li>毎月のミーティング食事会への応募券</li>
        <li>毎月10,000 CPの付与</li>
        <li>毎月1,000 EPの付与</li>
      </ul>
    </div>
  );
};

const PremiumPlan = ({
  priceId,
  selectedPriceId,
  amount,
  onClick,
}: {
  priceId: string;
  selectedPriceId: string;
  amount: number;
  onClick: (priceId: string) => void;
}) => {
  return (
    <div
      className={classnames(css.list, css.type, { [css.selected]: priceId === selectedPriceId })}
      onClick={() => onClick(priceId)}
    >
      <h2 className={css.title}>
        プレミアム
        <span>
          {new Intl.NumberFormat("ja-JP", { style: "currency", currency: "JPY" }).format(amount)}
          /月(税別)
        </span>
      </h2>
      <ul className={css.items}>
        <li>Facebookサロンの参加</li>
        <li>選挙への参加</li>
        <li>運営スタッフとして立候補</li>
        <li>
          年次総会への<b>参加権</b>
        </li>
        <li>
          毎月のミーティング食事会への<b>参加権</b>
        </li>
        <li>毎月50,000 CPの付与</li>
        <li>毎月5,000 EPの付与</li>
      </ul>
    </div>
  );
};

const Plan = () => {
  const location = useLocation();
  const { loading, user } = useAuth();
  const { basicPlan, premiumPlan } = usePlan();
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [actionLoading, setActionLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      navigate("/signin");
      return;
    }
    if (user.subscription) {
      navigate("/mypage");
      return;
    }
  }, [loading, user]);

  useEffect(() => {
    if (selectedPlanId !== "") {
      return;
    }
    if (basicPlan) {
      setSelectedPlanId(basicPlan.id);
      return;
    }
    if (premiumPlan) {
      setSelectedPlanId(premiumPlan.id);
      return;
    }
  }, [basicPlan, premiumPlan]);

  const onClickCheckout = async () => {
    try {
      setActionLoading(true);
      const session = await createCheckoutSession(
        selectedPlanId,
        `${location.origin}/thankyou`,
        `${location.origin}/cancel`,
      );
      window.location.assign(session.url);
    } catch (e) {
      console.error(e);
      window.alert("エラーが発生しました");
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <main className="_main">
      <section className="_section">
        <h1 className="_h1">プラン選択</h1>
        {basicPlan?.unit_amount && (
          <BasicPlan
            priceId={basicPlan.id}
            selectedPriceId={selectedPlanId}
            amount={basicPlan.unit_amount}
            onClick={setSelectedPlanId}
          />
        )}
        {premiumPlan?.unit_amount && (
          <PremiumPlan
            priceId={premiumPlan.id}
            selectedPriceId={selectedPlanId}
            amount={premiumPlan.unit_amount}
            onClick={setSelectedPlanId}
          />
        )}
        <p>※ プラン内容に関しては今後拡充・変更予定です。</p>
        <p>
          <LoadingButton
            variant="contained"
            color="primary"
            size="large"
            loading={actionLoading}
            onClick={onClickCheckout}
            style={{ width: "250px", borderRadius: "50px" }}
          >
            次へ
          </LoadingButton>
        </p>
      </section>
    </main>
  );
};

export default Plan;
