import { ReactNode } from "react";
import { Link } from "gatsby";
import { Button, PropTypes } from "@material-ui/core";

export type LinkButtonProps = {
  to: string;
  color: PropTypes.Color;
  children: ReactNode;
};

export const LinkButton = ({ to, color, children }: LinkButtonProps) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Button
        type="button"
        variant="contained"
        size="large"
        color={color}
        style={{ width: "250px", borderRadius: "50px" }}
      >
        {children}
      </Button>
    </Link>
  );
};

export const FacebookGroupLinkButton = () => {
  return (
    <a
      href="https://www.facebook.com/groups/616068785834885/"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: "none" }}
    >
      <Button
        type="button"
        variant="contained"
        size="large"
        style={{
          width: "250px",
          borderRadius: "50px",
          color: "#ffffff",
          backgroundColor: "#217cef",
          textTransform: "none",
        }}
      >
        Facebookグループへ
      </Button>
    </a>
  );
};
