import { selector } from "recoil";
import CryptoJS from "crypto-js";
import { authState } from "../atoms/auth";

const base64Key = "VzfRtdOwL18Z/oQFpy+WxYx+jzXx24kALJp4CnKCcGQ=";

export const pointEncryptState = selector({
  key: "point/encrypt",
  get: ({ get }) => {
    const state = get(authState);
    if (!state.user) {
      return "";
    }
    const cryptKey = CryptoJS.enc.Base64.parse(base64Key);

    const src = JSON.stringify({
      time: Math.floor(Date.now() / 1000),
      edo_id: state.user.uid,
      uniform_number: state.user.membershipId,
    });
    const encrypted = CryptoJS.AES.encrypt(src, cryptKey, { mode: CryptoJS.mode.ECB });
    return encrypted.toString();
  },
});
