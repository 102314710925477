import { useEffect } from "react";
import { navigate } from "gatsby";
import classnames from "classnames";
import { Box } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import { Environment } from "@membership-edo/types";
import { FirebaseAuth } from "../components/FirebaseAuth";
import { SignUpForm } from "../components/debug/SignUpForm";
import { LinkButton } from "../components/Button";
import { useAuth } from "../hooks/auth";
import * as css from "./signup.module.scss";

export default function SignUp() {
  const { loading, user } = useAuth();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      return;
    }
    if (user.subscription) {
      navigate("/mypage");
      return;
    }
    navigate("/plan");
  }, [loading, user]);

  if (loading) {
    return (
      <Box className={css.loading}>
        <CircularProgress sx={{ color: "#000000" }} />
      </Box>
    );
  }

  return (
    <main className={classnames(css.main, "_main")}>
      <section className="_section">
        <h1 className="_h1">新規サロンメンバー登録</h1>
        <p>Edo All United へようこそ！</p>
        <p>早速、コミュニティに入って仲間たちと共にあなたの理想のサッカーチームを作りましょう！</p>
        <p>
          <a
            href="https://nowdo.notion.site/5e5a6226f8f341f0af5db374f04d7cfa"
            target="_blank"
            rel="noreferrer"
          >
            利用規約
          </a>
          と
          <a
            href="https://nowdo.notion.site/d4cc0cfefca44d77baae5747436f98b7"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
          をご確認下さい。
        </p>
        <div className={css.mb2}>
          <FirebaseAuth title="Facebookで新規登録" />
        </div>
        <div className={css.mb2}>
          <LinkButton to="/signin" color="secondary">
            ログインはこちら
          </LinkButton>
        </div>
        {process.env.GATSBY_ENVIRONMENT === Environment.local && <SignUpForm />}
      </section>
    </main>
  );
}
