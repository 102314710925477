import { useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import { Button } from "@material-ui/core";
import { LoadingButton } from "@mui/lab";
import { createPortalLink } from "@membership-edo/stripe";
import { useLocation } from "@reach/router";
import { useAuth } from "../hooks/auth";
import { usePlan } from "../hooks/plan";
import { FacebookGroupLinkButton, LinkButton } from "../components/Button";

const MyPage = () => {
  const location = useLocation();
  const { loading, user, signOut } = useAuth();
  const { basicPlan, premiumPlan } = usePlan();
  const [changePaymentLoading, setChangePaymentLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      return;
    }
    navigate("/signin");
  }, [loading]);

  const epochToNextCheckout = (datetime: string): string => {
    const date = new Date(datetime);
    return dayjs(date).format("YYYY/MM/DD");
  };

  const convertDateString = (datetime: string): string => {
    return dayjs(datetime).format("YYYY/MM/DD HH:mm");
  };

  const paperFormParams = useMemo(() => {
    if (!user) {
      return "";
    }

    const params = new URLSearchParams();
    params.append("name", user.name ?? "");
    params.append("customer_id", user.customerId ?? "");
    if (user.subscription) {
      console.log(JSON.stringify(user.subscription));
      params.append("expire_at", epochToNextCheckout(user.subscription.current_period_end));
      params.append("plan", user.subscription.price);
    }
    return params.toString();
  }, [user]);

  const subscription = useMemo(() => {
    if (!user?.subscription || !basicPlan || !premiumPlan) {
      return null;
    }

    let plan = null;
    if (user.subscription.price === basicPlan.id) {
      plan = basicPlan;
    } else if (user.subscription.price === premiumPlan.id) {
      plan = premiumPlan;
    }
    if (!plan) {
      return null;
    }

    return {
      name: plan.description,
      amount: plan.unit_amount,
      nextPeriodStart: epochToNextCheckout(user.subscription.current_period_end),
    };
  }, [user?.subscription, basicPlan, premiumPlan]);

  const changeSubscriptionUrl = useMemo(() => {
    return `https://hj6eiox6.paperform.co/?${paperFormParams}`;
  }, [paperFormParams]);

  const cancelSubscriptionUrl = useMemo(() => {
    return `https://2joehhir.paperform.co/?${paperFormParams}`;
  }, [paperFormParams]);

  const onClickCreatePortalLink = async () => {
    try {
      setChangePaymentLoading(true);
      const session = await createPortalLink(location.href);
      window.location.assign(session.url);
    } catch (e) {
      window.alert("エラーが発生しました");
    } finally {
      setChangePaymentLoading(false);
    }
  };

  return (
    <main className="_main">
      <section className="_section">
        <h1 className="_h1">マイページ</h1>
        <h2 className="_h2">背番号</h2>
        <p>{user?.membershipId ?? ""}</p>
        <h2 className="_h2">Charge Point</h2>
        <p>{user?.point?.xp ?? "-"}</p>
        <h2 className="_h2">会員登録日</h2>
        {user && user.creationTime && <p>{convertDateString(user.creationTime)}</p>}
        <h2 className="_h2">現在のプラン</h2>
        {subscription ? (
          <>
            <p>
              {subscription.name}（¥{subscription.amount}/月）
            </p>
            <p>
              <a
                href={changeSubscriptionUrl}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  color="primary"
                  style={{ width: "250px", borderRadius: "50px" }}
                >
                  プラン変更申請
                </Button>
              </a>
            </p>
            <p>
              <a
                href={cancelSubscriptionUrl}
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  color="secondary"
                  style={{ width: "250px", borderRadius: "50px" }}
                >
                  退会申請
                </Button>
              </a>
            </p>
            <h2 className="_h2">次回の請求</h2>
            <p>{subscription.nextPeriodStart}</p>
            <p>
              <LoadingButton
                loading={changePaymentLoading}
                variant="contained"
                size="large"
                onClick={onClickCreatePortalLink}
                style={{ width: "250px", borderRadius: "50px" }}
              >
                支払い方法の変更
              </LoadingButton>
            </p>
            <p>
              ベーシックプラン、プレミアムプランの方はこちらよりFacebookグループへの参加リクエストを申請ください。
              <span className="red bold">
                Facebookグループに申請しないと、サービスが利用できません！
              </span>
            </p>
            <p>
              <FacebookGroupLinkButton />
            </p>
          </>
        ) : (
          <>
            <p>現在プランに加入してません。</p>
            <LinkButton to="/plan" color="primary">
              プランの選択へ
            </LinkButton>
          </>
        )}
      </section>
      <section className="_section">
        <a
          className="_h1"
          href="/"
          onClick={async (e) => {
            e.preventDefault();
            try {
              await signOut();
              await navigate("/signin");
            } catch (e) {
              console.error(e);
            }
          }}
        >
          ログアウト
        </a>
        <a
          className="_h1"
          href="https://nowdo.notion.site/5e5a6226f8f341f0af5db374f04d7cfa"
          target="_blank"
          rel="noreferrer"
        >
          利用規約
        </a>
        <a
          className="_h1"
          href="https://nowdo.notion.site/d4cc0cfefca44d77baae5747436f98b7"
          target="_blank"
          rel="noreferrer"
        >
          プライバシーポリシー
        </a>
        <a
          className="_h1"
          href="https://i43e0kde.paperform.co/?service_name=Edo%20All%20United"
          target="_blank"
          rel="noreferrer"
        >
          問い合わせ
        </a>
        <a
          className="_h1"
          href="https://www.notion.so/OneTokyo-c9b5a453ec5b4922a4e1697f4393ce39"
          target="_blank"
          rel="noreferrer"
        >
          ヘルプ
        </a>
      </section>
    </main>
  );
};

export default MyPage;
