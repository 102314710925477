import { atom } from "recoil";
import { User } from "@membership-edo/types";

export const authState = atom<{
  loading: boolean;
  user: User | null;
}>({
  key: "auth",
  default: {
    loading: true,
    user: null,
  },
});
