import { ReactNode } from "react";
import { RecoilRoot } from "recoil";
import Layout from "./src/components/Layout";
import "./src/stylesheets/lp.scss";

export const wrapPageElement = ({ element, props }: { element: ReactNode; props: any }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }: { element: ReactNode }) => {
  return <RecoilRoot>{element}</RecoilRoot>;
};
