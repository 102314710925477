import { useEffect, useMemo } from "react";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signOut as signOutFirebase,
} from "firebase/auth";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { auth, getUser, waitForMembershipUser } from "@membership-edo/firebase/web";
import { getSubscription } from "@membership-edo/stripe";
import { authState } from "../recoil/atoms/auth";
import { subscriptionSelector } from "../recoil/selectors/subscription";

export const useAuthEffect = () => {
  const [, setState] = useRecoilState(authState);

  useEffect(() => {
    return onAuthStateChanged(auth, async (it) => {
      if (!it) {
        setState({ loading: false, user: null });
        return;
      }

      const [u, s] = await Promise.all([getUser(it.uid), getSubscription()]);

      setState({
        loading: false,
        user: {
          uid: it.uid,
          email: it.email ?? "",
          name: it.displayName ?? "",
          photoURL: it.photoURL ?? "",
          customerId: u?.customerId ?? "",
          membershipId: u?.membershipId ?? "",
          subscription: s,
          point: u?.point ?? { xp: 0, np: 0 },
          creationTime: it.metadata.creationTime,
        },
      });
    });
  }, []);
};

export const useAuth = () => {
  const [state, setState] = useRecoilState(authState);
  const subscription = useRecoilValueLoadable(subscriptionSelector);

  const user = useMemo(() => {
    if (!state.user) {
      return null;
    }
    if (subscription.state === "hasValue") {
      return { ...state.user, subscription: subscription.contents };
    }
    return state.user;
  }, [state.user, subscription.state]);

  return {
    loading: state.loading,
    subscriptionLoading: subscription.state === "loading",
    user,
    signUpDebug: (email: string, password: string) => {
      return createUserWithEmailAndPassword(auth, email, password);
    },
    signIn: () => {
      setState({ ...state, loading: true });
      const provider = new FacebookAuthProvider();
      provider.addScope("public_profile");
      provider.addScope("email");
      return signInWithRedirect(auth, provider);
    },
    signInDebug: (email: string, password: string) => {
      return signInWithEmailAndPassword(auth, email, password);
    },
    signOut: () => {
      setState({ ...state, loading: true });
      return signOutFirebase(auth);
    },
    watchMembershipId: async () => {
      if (!state.user) {
        return;
      }
      const user = await waitForMembershipUser(state.user.uid);
      setState({ ...state, user: { ...state.user, membershipId: user.membershipId } });
    },
  };
};
