import { collection, getDocs, limit, query, where } from "@firebase/firestore";
import type {
  DocumentData,
  FirestoreDataConverter,
  Query,
  QueryConstraint,
  QueryDocumentSnapshot,
} from "@firebase/firestore";
import {
  GetProductsOptions,
  Price,
  Product,
  StripePaymentsError,
} from "@stripe/firestore-stripe-payments";
import { firestore } from "@membership-edo/firebase/web";
import { collectionNamePrices, collectionNameProducts } from "@membership-edo/types";

const productFirestoreDataConverter: FirestoreDataConverter<Product> = {
  toFirestore: () => {
    throw new Error("Not implemented for readonly Product type.");
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Product => {
    return {
      ...(snapshot.data() as Product),
      id: snapshot.id,
      prices: [],
    };
  },
};

const priceFirestoreDataConverter: FirestoreDataConverter<Price> = {
  toFirestore: () => {
    throw new Error("Not implemented for readonly Price type.");
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Price => {
    const data: DocumentData = snapshot.data();
    return {
      ...(data as Price),
      id: snapshot.id,
      product: snapshot.ref.parent.parent.id,
    };
  },
};

export async function getProducts(options?: GetProductsOptions): Promise<Product[]> {
  try {
    const { includePrices } = options ?? {};

    const productsQuery: Query<Product> = collection(
      firestore,
      collectionNameProducts,
    ).withConverter(productFirestoreDataConverter);

    const constraints: QueryConstraint[] = [];
    if (options?.activeOnly) {
      constraints.push(where("active", "==", true));
    }

    if (options?.where) {
      for (const filter of options.where) {
        constraints.push(where(...filter));
      }
    }

    if (typeof options?.limit !== "undefined") {
      constraints.push(limit(options.limit));
    }

    const querySnap =
      constraints.length > 0
        ? await getDocs(query(productsQuery, ...constraints))
        : await getDocs(productsQuery);

    const products = querySnap.docs.map((snap: QueryDocumentSnapshot<Product>) => {
      return snap.data();
    });

    if (includePrices) {
      const productsWithPrices: Promise<Product>[] = products.map((product: Product) =>
        getProductWithPrices(product),
      );
      return await Promise.all(productsWithPrices);
    }

    return products;
  } catch (error) {
    throw new StripePaymentsError("internal", "Unexpected error while querying Firestore", error);
  }
}

async function getProductWithPrices(product: Product): Promise<Product> {
  const querySnap = await getDocs(
    collection(firestore, collectionNameProducts, product.id, collectionNamePrices).withConverter(
      priceFirestoreDataConverter,
    ),
  );

  const prices: Price[] = querySnap.docs.map((snap: QueryDocumentSnapshot<Price>) => snap.data());

  return { ...product, prices };
}
