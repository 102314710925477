import * as React from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import { useAuth } from "../hooks/auth";
import * as css from "./index.module.scss";
import { LinkButton } from "../components/Button";

export function Index() {
  const { user } = useAuth();

  return (
    <div className={css.root}>
      <main className={classnames(css._main)}>
        <div className={css.inner}>
          <section className={classnames("_section", css._section)}>
            <h1 className="_h1 big">
              東京から
              <br />
              世界を一つに
            </h1>
            <p>
              Edo All United はオーナーやフロントが権力を持ってなにかを決めるといったことはしません。サポーターによる選挙や投票を通してクラブ経営に必要なものごとを決めていきます。サポーターのみなさんが関わり続ける、そして関わっていると思える運営システムを維持し続けることを目指します。
            </p>
            <div className="_section__textbox">
              <h2 className="title">クラブコンセプト</h2>
              <ol>
                <li>全員参加型クラブ経営</li>
                <li>オープン型クラブ経営</li>
                <li>最先端クラブ経営</li>
                <li>スポーツマンシップ</li>
              </ol>
            </div>
          </section>
          <section className={classnames("_section", css._section)}>
            <h1 className="_h1 big">選挙について</h1>
            <p>
              サポーター全員が参加できるクラブ経営のため、人選や運営に関する決めごと（方針、ルール、エンブレムなど）は選挙を通じて決定します。
            </p>
            <div className="_section__textbox">
              <h2 className="title">選挙の流れ</h2>
              <ol>
                <li>サロン会員が選挙を申請</li>
                <li>サロンマスターが選挙を承認</li>
                <li>選挙を実施</li>
                <li>当選後MTGの様子をLIVE配信</li>
              </ol>
            </div>
          </section>
          <section className={classnames("_section", css._section)}>
            <h1 className="_h1 big">サロンメンバー登録</h1>
            <p>
              Edo All United では、クラブ経営に関わるサロンメンバーを募集しています。サロンの収益はすべてクラブ経営（グラウンド代、ユニフォーム代、その他運営に関わる経費）に充てられ、スポンサーによる支援に依存しすぎない全く新しいクラブ経営を目指します。
            </p>
            <p>プラン内容に関しては今後拡充・変更予定です。</p>
            <div className="_section__textbox basic">
              <h2 className="title">ベーシック会員 ¥10,000/月(税別)</h2>
              <ul>
                <li>Facebookサロンの参加</li>
                <li>選挙への参加</li>
                <li>運営スタッフとして立候補</li>
                <li>年次総会への応募権</li>
                <li>毎月のミーティング食事会への応募券</li>
                <li>毎月10,000 CPの付与 ※</li>
                <li>毎月1,000 EPの付与 ※</li>
              </ul>
            </div>
            <div className="_section__textbox premium">
              <h2 className="title">プレミアム会員 ¥50,000/月(税別)</h2>
              <ul>
                <li>Facebookサロンの参加</li>
                <li>選挙への参加</li>
                <li>運営スタッフとして立候補</li>
                <li>
                  年次総会への<b>参加権</b>
                </li>
                <li>
                  毎月のミーティング食事会への<b>参加権</b>
                </li>
                <li>毎月50,000 CPの付与 ※</li>
                <li>毎月5,000 EPの付与 ※</li>
              </ul>
            </div>
            <p>※ 詳細は下記ポイントシステムをご確認ください</p>
            <p>
              {user && (
                <LinkButton to="/mypage" color="primary">
                  マイページ
                </LinkButton>
              )}
              {!user && (
                <LinkButton to="/signup" color="primary">
                  サロンメンバー登録へ
                </LinkButton>
              )}
            </p>
          </section>
          <section className={classnames("_section", css._section)}>
            <h1 className="_h1 big">ポイントシステム</h1>
            <p>クラブへの貢献を可視化するためのポイントシステムを開発予定です。</p>
            <div className="_section__textbox">
              <h2 className="title">CP (Charge point)</h2>
              <ol>
                <li>貢献度を可視化するポイント</li>
                <li>ポイント数によってランク分け</li>
                <li>ランクによって特典を得られる</li>
              </ol>
            </div>
            <div className="_section__textbox">
              <h2 className="title">EP (Edo Point)</h2>
              <ol>
                <li>サービスやデジタルコンテンツを購入できる</li>
                <li>デジタルコンテンツを通じて選手などに経済的な支援ができる</li>
              </ol>
            </div>
          </section>
          <section className={classnames("_section", css._section)}>
            <h1 className="_h1 big">クラブ情報</h1>
            <div className="_section__textbox">
              <h2 className="title">運営</h2>
              <ul>
                <li>発起人：本田圭佑</li>
                <li>運営責任者：平井孝明</li>
                <li>代表者：鈴木良介</li>
                <li>NowDo Dev-Team</li>
              </ul>
            </div>
            <div className="_section__textbox">
              <h2 className="title">クラブ情報</h2>
              <ul>
                <li>登録名：EDO ALL UNITED</li>
                <li>所属：東京都社会人サッカーリーグ1部</li>
                <li>登録エリア：東京都中央区</li>
              </ul>
            </div>
          </section>
          <section className={classnames("_section", css._section)}>
            {user && (
              <Link to="/mypage" className="_h1">
                マイページ
              </Link>
            )}
            {!user && (
              <>
                <Link to="/signup" className="_h1">
                  新規メンバー登録
                </Link>
                <Link to="/signin" className="_h1">
                  メンバーログイン
                </Link>
              </>
            )}
            <a
              className="_h1"
              href="https://magazine.onetokyo.jp/"
              target="_blank"
              rel="noreferrer"
            >
              EDO MAGAZINE
            </a>
            <a className="_h1" href="https://www.notion.so/nowdo/5e5a6226f8f341f0af5db374f04d7cfa">
              利用規約
            </a>
            <a
              className="_h1"
              href="https://nowdo.notion.site/d4cc0cfefca44d77baae5747436f98b7"
              target="_blank"
              rel="noreferrer"
            >
              プライバシーポリシー
            </a>

            <a
              className="_h1"
              href="https://i43e0kde.paperform.co/?service_name=Edo%20All%20United"
              target="_blank"
              rel="noreferrer"
            >
              問い合わせ
            </a>
            <a
              className="_h1"
              href="https://www.notion.so/nowdo/OneTokyo-c9b5a453ec5b4922a4e1697f4393ce39"
              target="_blank"
              rel="noreferrer"
            >
              ヘルプ
            </a>
          </section>
        </div>
      </main>
    </div>
  );
}

export default Index;
