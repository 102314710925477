import { addDoc, collection, onSnapshot } from "@firebase/firestore";
import type {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  FirestoreDataConverter,
  FirestoreError,
  QueryDocumentSnapshot,
  Timestamp,
  Unsubscribe,
} from "@firebase/firestore";
import {
  CREATE_SESSION_TIMEOUT_MILLIS,
  CreateCheckoutSessionOptions,
  LineItemSessionCreateParams,
  PriceIdSessionCreateParams,
  Session,
  SessionCreateParams,
  StripePayments,
  StripePaymentsError,
} from "@stripe/firestore-stripe-payments";
import {
  checkNonEmptyArray,
  checkNonEmptyString,
  checkPositiveNumber,
} from "@stripe/firestore-stripe-payments/lib/utils";
import { getCurrentUser } from "./user";
import { collectionNameCheckoutSessions, collectionNameCustomers } from "@membership-edo/types";
import { firestore } from "@membership-edo/firebase/web";

async function addSessionDoc(uid: string, params: SessionCreateParams): Promise<DocumentReference> {
  try {
    return await addDoc(
      collection(firestore, collectionNameCustomers, uid, collectionNameCheckoutSessions),
      params,
    );
  } catch (err) {
    throw new StripePaymentsError("internal", "Error while querying Firestore.", err);
  }
}

function waitForSessionId(doc: DocumentReference, timeoutMillis: number): Promise<Session> {
  let cancel: Unsubscribe;
  return new Promise<Session>((resolve, reject) => {
    const timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      reject(
        new StripePaymentsError("deadline-exceeded", "Timeout while waiting for session response."),
      );
    }, timeoutMillis);
    cancel = onSnapshot(
      doc.withConverter(sessionFirestoreDataConverter),
      (snap: DocumentSnapshot<PartialSession>) => {
        const session: PartialSession | undefined = snap.data();
        if (hasSessionId(session)) {
          clearTimeout(timeout);
          resolve(session);
        }
      },
      (err: FirestoreError) => {
        clearTimeout(timeout);
        reject(new StripePaymentsError("internal", "Error while querying Firestore.", err));
      },
    );
  }).finally(() => cancel());
}

export async function createCheckoutSession(
  params: SessionCreateParams,
  options?: CreateCheckoutSessionOptions,
): Promise<Session> {
  params = { ...params };
  checkAndUpdateCommonParams(params);
  if (hasLineItems(params)) {
    checkLineItemParams(params);
  } else {
    checkPriceIdParams(params);
  }

  const timeoutMillis: number = getTimeoutMillis(options?.timeoutMillis);

  const uid = getCurrentUser();

  const doc = await addSessionDoc(uid, params);

  return waitForSessionId(doc, timeoutMillis);
}

function checkAndUpdateCommonParams(params: SessionCreateParams): void {
  if (typeof params.cancel_url !== "undefined") {
    checkNonEmptyString(params.cancel_url, "cancel_url must be a non-empty string.");
  } else {
    params.cancel_url = window.location.href;
  }

  params.mode ??= "subscription";
  if (typeof params.success_url !== "undefined") {
    checkNonEmptyString(params.success_url, "success_url must be a non-empty string.");
  } else {
    params.success_url = window.location.href;
  }
}

function hasLineItems(params: SessionCreateParams): params is LineItemSessionCreateParams {
  return "line_items" in params;
}

function checkLineItemParams(params: LineItemSessionCreateParams): void {
  checkNonEmptyArray(params.line_items, "line_items must be a non-empty array.");
}

function checkPriceIdParams(params: PriceIdSessionCreateParams): void {
  checkNonEmptyString(params.price, "price must be a non-empty string.");
  if (typeof params.quantity !== "undefined") {
    checkPositiveNumber(params.quantity, "quantity must be a positive integer.");
  }
}

function getTimeoutMillis(timeoutMillis: number | undefined): number {
  if (typeof timeoutMillis !== "undefined") {
    checkPositiveNumber(timeoutMillis, "timeoutMillis must be a positive number.");
    return timeoutMillis;
  }

  return CREATE_SESSION_TIMEOUT_MILLIS;
}

type PartialSession = Partial<Session>;

function hasSessionId(session: PartialSession | undefined): session is Session {
  return typeof session?.id !== "undefined";
}

const sessionFirestoreDataConverter: FirestoreDataConverter<PartialSession> = {
  toFirestore: (): DocumentData => {
    throw new Error("Not implemented for readonly Session type.");
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): PartialSession => {
    const { created, sessionId, ...rest } = snapshot.data();
    if (typeof sessionId !== "undefined") {
      return {
        ...(rest as Session),
        id: sessionId,
        created_at: toUTCDateString(created),
      };
    }

    return { ...(rest as Session) };
  },
};

function toUTCDateString(timestamp: Timestamp): string {
  return timestamp.toDate().toUTCString();
}
