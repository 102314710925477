import { selector } from "recoil";
import { getSubscription } from "@membership-edo/stripe";
import { authState } from "../atoms/auth";
import { subscriptionRefresherState } from "../atoms/subscription";

export const subscriptionSelector = selector({
  key: "subscriptionSelector",
  get: async ({ get }) => {
    get(subscriptionRefresherState);
    const state = get(authState);
    if (state.loading || !state.user) {
      return null;
    }
    try {
      return await getSubscription();
    } catch (e) {
      console.error(e);
      return null;
    }
  },
});
