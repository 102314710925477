import { useEffect } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import classnames from "classnames";
import { Box } from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import { Environment } from "@membership-edo/types";
import { FirebaseAuth } from "../components/FirebaseAuth";
import { SignInForm } from "../components/debug/SignInForm";
import { useAuth } from "../hooks/auth";
import { LinkButton } from "../components/Button";
import * as css from "./signin.module.scss";

export default function SignIn() {
  const location = useLocation();
  const { loading, user } = useAuth();

  const redirectTo = new URLSearchParams(location.search).get("redirect_to");

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      return;
    }
    if (redirectTo) {
      navigate(redirectTo);
      return;
    }
    navigate("/mypage");
  }, [loading, user]);

  if (loading) {
    return (
      <Box className={css.loading}>
        <CircularProgress sx={{ color: "#000000" }} />
      </Box>
    );
  }
  return (
    <main className={classnames(css.main, "_main")}>
      <section className="_section">
        <h1 className="_h1">サロンメンバーログイン</h1>
        <div className={css.mb2}>
          <FirebaseAuth />
        </div>
        <div className={css.mb2}>
          <LinkButton to="/signup" color="secondary">
            新規登録はこちら
          </LinkButton>
        </div>
        {process.env.GATSBY_ENVIRONMENT === Environment.local && <SignInForm />}
      </section>
    </main>
  );
}
