import {
  collection,
  doc,
  DocumentData,
  FirestoreDataConverter,
  getDoc,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { collectionNamePoint, Point } from "@membership-edo/types";
import { firestore } from "./firebase";

export const PointDataConverter: FirestoreDataConverter<Point> = {
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined): Point {
    if (!snapshot.exists()) {
      return undefined;
    }
    const data = snapshot.data(options);

    return {
      xp: data.xp ?? 0,
      np: data.np ?? 0,
    };
  },
  toFirestore(u: PartialWithFieldValue<Point> | WithFieldValue<Point>): DocumentData {
    const data = {} as DocumentData;
    ["xp", "np"].forEach((it) => {
      if (u[it]) {
        data[it] = u[it];
      }
    });
    return data;
  },
};

export const getPoint = async (uid: string): Promise<Point> => {
  const s = await getDoc(
    doc(collection(firestore, collectionNamePoint).withConverter(PointDataConverter), uid),
  );
  if (!s.exists()) {
    return { xp: 0, np: 0 };
  }
  return s.data();
};
