import { StripePaymentsError } from "@stripe/firestore-stripe-payments";
import { auth } from "@membership-edo/firebase/web";

export function getCurrentUser(): string {
  const currentUser: string | undefined = auth.currentUser?.uid;
  if (!currentUser) {
    throw new StripePaymentsError(
      "unauthenticated",
      "Failed to determine currently signed in user. User not signed in.",
    );
  }

  return currentUser;
}
