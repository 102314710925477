import {
  Product,
  Subscription,
  Session,
  SessionCreateParams,
} from "@stripe/firestore-stripe-payments";
import { httpsCallable } from "firebase/functions";
import { functions } from "@membership-edo/firebase/web";
import { getProducts } from "./product";
import { createCheckoutSession as createStripeCheckoutSession } from "./session";
import { getCurrentUserSubscriptions, onCurrentUserSubscriptionUpdate } from "./subscription";

export const getProduct = async (): Promise<Product> => {
  const products = await getProducts({ activeOnly: true, includePrices: true, limit: 1 });
  if (!products.length) {
    throw new Error("product is not found");
  }
  return products[0];
};

export const getSubscription = async (): Promise<Subscription | undefined> => {
  const subscriptions = await getCurrentUserSubscriptions();
  if (subscriptions.length) {
    return subscriptions.find((it) => it.status === "active" || it.status === "trialing");
  }

  // fallback functions
  try {
    const data = await httpsCallable<unknown, Subscription>(functions, "getSubscription")();
    return data.data;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

export const onWatchSubscription = async (): Promise<Subscription> => {
  return new Promise((resolve, reject) => {
    onCurrentUserSubscriptionUpdate(
      (snapshot) => {
        resolve(
          snapshot.subscriptions.find((it) => it.status === "active" || it.status === "trialing"),
        );
      },
      (error) => {
        console.error(error);
        reject(error);
      },
    );
  });
};

export const createCheckoutSession = async (
  price: string,
  successUrl?: string,
  cancelUrl?: string,
): Promise<Session> => {
  const params = {
    price,
    allow_promotion_codes: true,
    tax_rates: [process.env.GATSBY_STRIPE_TAX_RATE_ID],
  } as SessionCreateParams & { tax_rates: string[] };
  if (successUrl) {
    params.success_url = successUrl;
  }
  if (cancelUrl) {
    params.cancel_url = cancelUrl;
  }
  return await createStripeCheckoutSession(params);
};

export const createPortalLink = async (returnUrl: string): Promise<Session> => {
  const response = await httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink",
  )({ returnUrl });
  return response.data as Session;
};
