import { ReactNode } from "react";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from "@material-ui/core";
import Header from "./Header";
import { useAuthEffect } from "../hooks/auth";

export default function Layout({ children }: { children: ReactNode }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#0033ff !important",
      },
      secondary: {
        main: "#aaaaaa",
        contrastText: "#ffffff",
      },
    },
  });

  useAuthEffect();

  return (
    <>
      <Helmet>
        <title>Edo All United オンラインサロン</title>
        <meta
          name="description"
          content={`東京のサッカークラブ "Edo All United" のオンラインサロン登録ページです`}
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0"
        />
        <meta property="og:url" content="https://membership.edo.jp" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Edo All United" />
        <meta property="og:locale" content="ja_JP" />
        <meta property="og:image" content="https://membership.edo.jp/images/ogp.png" />
        <meta
          property="og:description"
          content="ぼくらはこれから、ゼロからサッカークラブを立ち上げる。"
        />
        <meta property="og:site_name" content="Edo All United" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="https://membership.edo.jp" />
        <meta name="twitter:title" content="Edo All United" />
        <meta
          name="twitter:description"
          content="ぼくらはこれから、ゼロからサッカークラブを立ち上げる。"
        />
        <meta name="twitter:image:src" content="https://membership.edo.jp/images/ogp.png" />

        <link rel="shortcut icon" type="image/x-icon" href="/images/favicon.ico" />
        <script src="https://www.googletagmanager.com/gtag/js?id=UA-150943436-4" async />
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag("js", new Date());

              gtag("config", "UA-150943436-4");
          `}
        </script>
        <style>{` html: { overflow-y: scroll; } `}</style>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="_white">{children}</div>
      </ThemeProvider>
    </>
  );
}
