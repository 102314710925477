import { Subscription } from "@stripe/firestore-stripe-payments";
import { Point } from "./point";

export type User = {
  uid: string;
  email: string;
  name: string;
  photoURL: string;
  customerId: string;
  membershipId: string;
  subscription?: Subscription;
  point: Point;
  creationTime?: string;
};

export const collectionNameUser = "User";
