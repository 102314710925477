import { useEffect } from "react";
import { navigate } from "gatsby";
import { useAuth } from "../hooks/auth";
import { FacebookGroupLinkButton, LinkButton } from "../components/Button";
import { useRecoilState } from "recoil";
import { subscriptionRefresherState } from "../recoil/atoms/subscription";
import { onWatchSubscription } from "@membership-edo/stripe";

export default function ThankYou() {
  const { loading, user, watchMembershipId } = useAuth();
  const [, setState] = useRecoilState(subscriptionRefresherState);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      navigate("/signin");
      return;
    }
    watchMembershipId().catch((error) => console.error(error));
    onWatchSubscription()
      .then(() => setState(new Date().getTime()))
      .catch((error) => console.error(error));
  }, [loading, user]);

  return (
    <main className="_main">
      <section className="_section">
        <h1 className="_h1">Edo All Unitedへようこそ！</h1>
        <p>Edo All Unitedへの登録が完了しました。</p>
        <p>
          ライブ配信などのサービスはFacebookグループ上で行いますので、以下より参加申請ください。
          <span className="red bold">
            Facebookグループに申請しないと、サービスが利用できません！
          </span>
        </p>
        <p>
          <FacebookGroupLinkButton />
        </p>
        <p>
          <LinkButton to="/mypage" color="secondary">
            マイページへ
          </LinkButton>
        </p>
      </section>
    </main>
  );
}
