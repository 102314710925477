import {
  collection,
  doc,
  DocumentData,
  FirestoreDataConverter,
  getDoc,
  onSnapshot,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Unsubscribe,
  WithFieldValue,
} from "firebase/firestore";
import { collectionNameUser, User } from "@membership-edo/types";
import { firestore } from "./firebase";

export const UserDataConverter: FirestoreDataConverter<User> = {
  fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions | undefined): User {
    if (!snapshot.exists()) {
      return undefined;
    }
    const data = snapshot.data(options);

    return {
      uid: snapshot.id,
      email: data.email ?? "",
      name: data.name ?? "",
      customerId: data.customerId ?? "",
      membershipId: data.membershipId ?? "",
      photoURL: data.photoURL ?? "",
      point: data.point ?? { xp: 0, np: 0 },
    };
  },
  toFirestore(u: PartialWithFieldValue<User> | WithFieldValue<User>): DocumentData {
    const data = {} as DocumentData;
    ["email", "name", "customerId", "membershipId", "photoURL", "point"].forEach((it) => {
      if (u[it]) {
        data[it] = u[it];
      }
    });
    return data;
  },
};

export const getUser = async (uid: string): Promise<User | undefined> => {
  console.log("getUser start");
  const s = await getDoc(
    doc(collection(firestore, collectionNameUser).withConverter(UserDataConverter), uid),
  );
  if (!s.exists()) {
    return undefined;
  }
  console.log("getUser end");
  return s.data();
};

export const waitForMembershipUser = (uid: string): Promise<User> => {
  let cancel: Unsubscribe;
  return new Promise<User>((resolve, reject) => {
    const timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      reject(new Error("Timeout while waiting for membership user"));
    }, 5000);
    cancel = onSnapshot(
      doc(collection(firestore, collectionNameUser).withConverter(UserDataConverter), uid),
      (snapshot) => {
        const user: User | undefined = snapshot.data();
        if (user && user.membershipId) {
          clearTimeout(timeout);
          resolve(user);
        }
      },
      (error) => {
        clearTimeout(timeout);
        reject(error);
      },
    );
  }).finally(() => cancel());
};
