import { useAuth } from "../hooks/auth";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

type Props = {
  title?: string;
};

export const FirebaseAuth = ({ title }: Props) => {
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    try {
      setLoading(true);
      await signIn();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      type="button"
      variant="contained"
      size="large"
      loading={loading}
      onClick={onClick}
      style={{
        backgroundColor: "#217cef",
        color: "#ffffff",
        width: "250px",
        borderRadius: "50px",
        textTransform: "none",
      }}
    >
      {title ?? "Facebookでログイン"}
    </LoadingButton>
  );
};
