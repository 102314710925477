import { collection, getDocs, onSnapshot, query, where } from "@firebase/firestore";
import type {
  DocumentChange,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
  FirestoreError,
  Query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Timestamp,
} from "@firebase/firestore";
import {
  GetSubscriptionsOptions,
  StripePaymentsError,
  Subscription,
  SubscriptionSnapshot,
  SubscriptionStatus,
} from "@stripe/firestore-stripe-payments";
import { checkNonEmptyArray } from "@stripe/firestore-stripe-payments/lib/utils";
import { firestore } from "@membership-edo/firebase/web";
import { collectionNameCustomers, collectionNameSubscriptions } from "@membership-edo/types";
import { getCurrentUser } from "./user";

function getStatusAsArray(status: SubscriptionStatus | SubscriptionStatus[]): SubscriptionStatus[] {
  if (typeof status === "string") {
    return [status];
  }

  checkNonEmptyArray(status, "status must be a non-empty array.");

  return status;
}

function toNullableUTCDateString(timestamp: Timestamp | null): string | null {
  if (timestamp === null) {
    return null;
  }

  return toUTCDateString(timestamp);
}

function toUTCDateString(timestamp: Timestamp): string {
  return timestamp.toDate().toUTCString();
}

const subscriptionFirestoreDataConverter: FirestoreDataConverter<Subscription> = {
  toFirestore: () => {
    throw new Error("Not implemented for readonly Subscription type.");
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Subscription => {
    const data: DocumentData = snapshot.data();
    const refs: DocumentReference[] = data.prices;
    const prices: Array<{ product: string; price: string }> = refs.map(
      (priceRef: DocumentReference) => {
        return {
          product: priceRef.parent.parent.id,
          price: priceRef.id,
        };
      },
    );

    return {
      cancel_at: toNullableUTCDateString(data.cancel_at),
      cancel_at_period_end: data.cancel_at_period_end,
      canceled_at: toNullableUTCDateString(data.canceled_at),
      created: toUTCDateString(data.created),
      current_period_start: toUTCDateString(data.current_period_start),
      current_period_end: toUTCDateString(data.current_period_end),
      ended_at: toNullableUTCDateString(data.ended_at),
      id: snapshot.id,
      metadata: data.metadata ?? {},
      price: (data.price as DocumentReference).id,
      prices,
      product: (data.product as DocumentReference).id,
      quantity: data.quantity ?? null,
      role: data.role ?? null,
      status: data.status,
      stripe_link: data.stripeLink,
      trial_end: toNullableUTCDateString(data.trial_end),
      trial_start: toNullableUTCDateString(data.trial_start),
      uid: snapshot.ref.parent.parent.id,
    };
  },
};

async function getSubscriptions(
  uid: string,
  options?: { status?: SubscriptionStatus[] },
): Promise<Subscription[]> {
  try {
    const subscriptionsQuery: Query<Subscription> = collection(
      firestore,
      collectionNameCustomers,
      uid,
      collectionNameSubscriptions,
    ).withConverter(subscriptionFirestoreDataConverter);

    const querySnap: QuerySnapshot<Subscription> = options?.status
      ? await getDocs(query(subscriptionsQuery, where("status", "in", options.status)))
      : await getDocs(subscriptionsQuery);

    return querySnap.docs.map((snap: QueryDocumentSnapshot<Subscription>) => snap.data());
  } catch (error) {
    throw new StripePaymentsError("internal", "Unexpected error while querying Firestore", error);
  }
}

export async function getCurrentUserSubscriptions(
  options?: GetSubscriptionsOptions,
): Promise<Subscription[]> {
  const queryOptions: { status?: SubscriptionStatus[] } = {};
  if (typeof options?.status !== "undefined") {
    queryOptions.status = getStatusAsArray(options.status);
  }

  const uid = getCurrentUser();
  return getSubscriptions(uid, queryOptions);
}

export function onCurrentUserSubscriptionUpdate(
  onUpdate: (snapshot: SubscriptionSnapshot) => void,
  onError?: (error: StripePaymentsError) => void,
): () => void {
  const uid: string = getCurrentUser();
  return onSnapshot(
    collection(firestore, collectionNameCustomers, uid, collectionNameSubscriptions).withConverter(
      subscriptionFirestoreDataConverter,
    ),
    (querySnap: QuerySnapshot<Subscription>) => {
      const snapshot: SubscriptionSnapshot = {
        subscriptions: [],
        changes: [],
        size: querySnap.size,
        empty: querySnap.empty,
      };
      querySnap.forEach((snap: QueryDocumentSnapshot<Subscription>) => {
        snapshot.subscriptions.push(snap.data());
      });
      querySnap.docChanges().forEach((change: DocumentChange<Subscription>) => {
        snapshot.changes.push({
          type: change.type,
          subscription: change.doc.data(),
        });
      });

      onUpdate(snapshot);
    },
    (err: FirestoreError) => {
      if (onError) {
        const arg: StripePaymentsError = new StripePaymentsError(
          "internal",
          `Error while listening to database updates: ${err.message}`,
          err,
        );
        onError(arg);
      }
    },
  );
}
