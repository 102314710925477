import { useMemo, useState } from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import { useAuth } from "../hooks/auth";
import * as css from "./Drawer.module.scss";

const DrawerContainer = ({ open, onLinkClicked }) => {
  const { user } = useAuth();

  const isLoggedIn = useMemo(() => {
    return !!user;
  }, [user]);

  return (
    <div className={classnames(css.DrawerContainer, { [css.open]: open })}>
      <div className={css.DrawerHeader}></div>
      <ul className={css.DrawerNavigation}>
        <li className={css.DrawerNavigationItem}>
          <Link to="/" onClick={onLinkClicked}>
            トップページ
          </Link>
        </li>
        {!isLoggedIn && (
          <>
            <li className={css.DrawerNavigationItem}>
              <Link to="/signup" onClick={onLinkClicked}>
                新規サロンメンバー登録
              </Link>
            </li>
            <li className={css.DrawerNavigationItem}>
              <Link to="/signin" onClick={onLinkClicked}>
                サロンメンバーログイン
              </Link>
            </li>
          </>
        )}
        {isLoggedIn && (
          <li className={css.DrawerNavigationItem}>
            <Link to="/mypage" onClick={onLinkClicked}>
              マイページ
            </Link>
          </li>
        )}
        <li className={css.DrawerNavigationItem}>
          <a className="_h1" href="https://magazine.onetokyo.jp/" target="_blank" rel="noreferrer">
            Edo MAGAZINE
          </a>
        </li>
        <li className={css.DrawerNavigationItem}>
          <a
            href="https://www.notion.so/nowdo/5e5a6226f8f341f0af5db374f04d7cfa"
            target="_blank"
            rel="noreferrer"
          >
            利用規約
          </a>
        </li>
        <li className={css.DrawerNavigationItem}>
          <a
            href="https://nowdo.notion.site/5e5a6226f8f341f0af5db374f04d7cfa"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
        </li>
        <li className={css.DrawerNavigationItem}>
          <a
            href="https://i43e0kde.paperform.co/?service_name=Edo%20All%20United"
            target="_blank"
            rel="noreferrer"
          >
            問い合わせ
          </a>
        </li>
        <li className={css.DrawerNavigationItem}>
          <a
            href="https://www.notion.so/OneTokyo-c9b5a453ec5b4922a4e1697f4393ce39"
            target="_blank"
            rel="noreferrer"
          >
            ヘルプ
          </a>
        </li>
      </ul>
    </div>
  );
};

export const Drawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className={classnames(css.DrawerButton, { [css.open]: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={css.DrawerButtonIcon}></span>
      </button>
      <DrawerContainer open={isOpen} onLinkClicked={() => setIsOpen(false)} />
    </>
  );
};
