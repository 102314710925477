import { useEffect } from "react";
import { navigate } from "gatsby";
import { LinkButton } from "../components/Button";
import { useAuth } from "../hooks/auth";

const Cancel = () => {
  const { loading, user } = useAuth();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (user) {
      return;
    }
    navigate("/signin");
  }, [loading, user]);

  return (
    <main className="_main">
      <section className="_section">
        <h1 className="_h1">決済がキャンセルされました。</h1>
        <p>お手数ですが、下記のボタンからもう一度決済を実行してください。</p>
        <p>
          <LinkButton to="/plan" color="primary">
            プランの選択へ
          </LinkButton>
        </p>
        <p>
          <LinkButton to="/mypage" color="secondary">
            マイページへ
          </LinkButton>
        </p>
      </section>
    </main>
  );
};

export default Cancel;
