import { useMemo } from "react";
import { useRecoilValueLoadable } from "recoil";
import { SubscriptionPlan } from "@membership-edo/types";
import { plansSelector } from "../recoil/selectors/plan";

export const usePlan = () => {
  const plans = useRecoilValueLoadable(plansSelector);

  const basicPlan = useMemo(() => {
    if (plans.state !== "hasValue") {
      return null;
    }
    return plans.contents.find((plan) => plan.metadata.plan === SubscriptionPlan.basicPlan);
  }, [plans]);

  const premiumPlan = useMemo(() => {
    if (plans.state !== "hasValue") {
      return null;
    }
    return plans.contents.find((plan) => plan.metadata.plan === SubscriptionPlan.premiumPlan);
  }, [plans]);

  return { basicPlan, premiumPlan };
};
