import { useEffect } from "react";
import { navigate } from "gatsby";
import { useRecoilValue } from "recoil";
import { Button } from "@material-ui/core";
import { pointEncryptState } from "../recoil/selectors/point";
import { useAuth } from "../hooks/auth";

const Point = () => {
  const { loading, user } = useAuth();
  const encryptedKey = useRecoilValue(pointEncryptState);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      navigate("/signin");
      return;
    }
  }, [loading, user]);

  return (
    <main className="_main">
      <section className="_section">
        {user?.subscription ? (
          <p>
            <form method="post" action="https://point.edo.jp/login">
              <input type="hidden" name="info" value={encryptedKey} />
              <Button
                type="button"
                variant="contained"
                size="large"
                style={{
                  width: "250px",
                  borderRadius: "50px",
                  color: "#ffffff",
                  backgroundColor: "#217cef",
                  textTransform: "none",
                }}
              >
                ポイントシステム（テスト版）へ
              </Button>
            </form>
          </p>
        ) : (
          <>プランに加入してください</>
        )}
      </section>
    </main>
  );
};

export default Point;
