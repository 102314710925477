import { selector } from "recoil";
import { getProduct } from "@membership-edo/stripe";

export const productSelector = selector({
  key: "productSelector",
  get: async () => {
    return await getProduct();
  },
});

export const plansSelector = selector({
  key: "plansSelector",
  get: async ({ get }) => {
    const product = get(productSelector);
    return product.prices.filter((price) => price.active);
  },
});
