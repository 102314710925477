import { useState } from "react";
import { navigate } from "gatsby";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAuth } from "../../hooks/auth";
import * as css from "./SignUpForm.module.scss";
import { TextField } from "@material-ui/core";

export const SignUpForm = () => {
  const { signUpDebug } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const onClick = async () => {
    try {
      setLoading(true);
      await signUpDebug(email, password);
      await navigate("/mypage");
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={css.flex}>
      <TextField
        required
        type="email"
        label="Email"
        defaultValue={email}
        onInput={(e) => setEmail((e.target as EventTarget & { value: string }).value)}
      />
      <TextField
        required
        type="password"
        label="Password"
        defaultValue={password}
        onInput={(e) => setPassword((e.target as EventTarget & { value: string }).value)}
        className={css.mb2}
      />
      <LoadingButton
        type="button"
        variant="contained"
        size="large"
        loading={loading}
        onClick={onClick}
      >
        登録
      </LoadingButton>
    </div>
  );
};
